import React from "react";
import ResourcesDashboard from "../../components/Resources";
import Seo from "../../components/seo";

const ResourcePage = (props) => (
  <>
    <Seo title="Resources" />
    <ResourcesDashboard {...props} />
  </>
);

export default ResourcePage;
