import React, { useEffect, useCallback, useState } from "react";
import {
  Box,
  Container,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
} from "@mui/material";
import { Wrapper } from "../Dashboard/styles";
import getResources from "../../services/getResources";
import { useAdminState, useAdminDispatch } from "../../context/AdminContext";
import Loading from "../../shared/Loading";
import {
  UserGroupIcon,
  PencilIcon,
  NoSymbolIcon,
} from "@heroicons/react/24/outline";
import { patchResource } from "../../httpRequests/resources";
import { SET_SNACKBAR } from "../../context/actions/snackbar";
import ByDomain from "./byDomain";
import ResourceByDomain from "../DashboardItem/PMO/ResourcesByDomain";

const ResourcesDashboard = () => {
  const dispatch = useAdminDispatch();
  const { resources, total, loading } = useAdminState().resources;
  const { login: { user: { profile } = {}, role } = {} } = useAdminState();
  const isEditable = role === "SuperAdmin" || role === "Admin";
  const [refetch, setRefetch] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedResource, setSelectedResource] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    total: "",
    salary: "",
    periodContract: "",
  });

  useEffect(() => {
    getResources();
  }, [refetch]);

  const handleOpen = (resource) => {
    setSelectedResource(resource);
    setFormData({
      name: resource.name,
      total: parseInt(resource.total, 10),
      salary: parseInt(resource.salary, 10),
      periodContract: parseInt(resource.periodContract, 10),
    });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedResource(null);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: name === "name" ? value : parseInt(value, 10),
    }));
  };

  const handleSave = async () => {
    const updatedData = {
      ...formData,
      updatedBy: profile?.given_name,
      vendor: "Total Resources",
    };

    try {
      const response = await patchResource(selectedResource.id, updatedData);
      dispatchSnackbar(
        response.status === 200,
        response.status === 200
          ? "Updated Successfully"
          : response.error.description
      );
      handleClose();
      getResources();
    } catch (error) {
      console.error("Failed to update resource:", error);
      dispatchSnackbar(false, "Failed to update resource");
    }
  };

  const dispatchSnackbar = useCallback(
    (success, message) => {
      dispatch({
        type: SET_SNACKBAR,
        payload: {
          open: true,
          message,
          severity: success ? "success" : "error",
        },
      });
    },
    [dispatch]
  );

  if (loading) return <Loading />;
  if (!resources)
    return <div className="no-permission">No resources available</div>;
  if (!isEditable)
    return (
      <div className="no-permission">
        <NoSymbolIcon />
        You don't have permission to see this page!
      </div>
    );

  return (
    <Wrapper>
      <Container className="container">
        <div className="page-header">
          <h2>Resources</h2>
        </div>
        <Box sx={{ flexGrow: 1 }}>
          <div className="dashboard-wrapper team-dashboard">
            <Grid container spacing={4}>
              <Grid item md={6} xs={12}>
                <div className="summary-item">
                  <div className="flex">
                    <h4 className="no-border">
                      <UserGroupIcon />
                      Total Resources
                    </h4>
                    <h4 className="no-border total type">{total}</h4>
                  </div>
                  <div className="detail-item flex no-border">
                    <ResourceTable
                      resources={resources}
                      onEdit={handleOpen}
                      isEditable={isEditable}
                    />
                  </div>
                </div>
              </Grid>

              <Grid item md={6} xs={12}>
                <ResourceByDomain
                  resources={resources.filter(
                    (i) => i.vendor !== "Total Resources"
                  )}
                />
              </Grid>

              <Grid item md={12} xs={12}>
                <div className="summary-item">
                  <div className="flex">
                    <h4 className="no-border">
                      <UserGroupIcon />
                      Resources By Domain
                    </h4>
                  </div>
                  <div className="detail-item flex no-border">
                    <ByDomain
                      resources={resources.filter(
                        (i) => i.vendor !== "Total Resources"
                      )}
                      onEdit={handleOpen}
                      refetch={refetch}
                      setRefetch={setRefetch}
                      isEditable={isEditable}
                      profile={profile}
                    />
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </Box>
      </Container>

      <EditDialog
        open={open}
        onClose={handleClose}
        formData={formData}
        onChange={handleChange}
        onSave={handleSave}
      />
    </Wrapper>
  );
};

const ResourceTable = ({ resources, onEdit, isEditable }) => (
  <Table>
    <TableHead>
      <TableRow>
        <TableCell>Employee Type</TableCell>
        <TableCell align="center">Total</TableCell>
        <TableCell align="center">Active</TableCell>
        <TableCell align="center">Vacant</TableCell>
        <TableCell align="center"></TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {resources
        .filter((i) => i.vendor === "Total Resources")
        .map((row) => (
          <TableRow key={row.id}>
            <TableCell>{row.name}</TableCell>
            <TableCell align="center">{row.total}</TableCell>
            <TableCell align="center">{row.salary}</TableCell>
            <TableCell align="center">{row.periodContract}</TableCell>
            <TableCell align="center">
              <IconButton onClick={() => onEdit(row)} disabled={!isEditable}>
                <PencilIcon style={{ width: 20, height: 20 }} />
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
    </TableBody>
  </Table>
);

const EditDialog = ({ open, onClose, formData, onChange, onSave }) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle>Edit Resource</DialogTitle>
    <DialogContent>
      <TextField
        margin="dense"
        name="name"
        label="Employee Type"
        value={formData.name}
        onChange={onChange}
        fullWidth
        disabled
      />
      <TextField
        margin="dense"
        name="total"
        label="Total"
        type="number"
        value={formData.total}
        onChange={onChange}
        fullWidth
      />
      <TextField
        margin="dense"
        name="salary"
        label="Active"
        type="number"
        value={formData.salary}
        onChange={onChange}
        fullWidth
      />
      <TextField
        margin="dense"
        name="periodContract"
        label="Vacant"
        type="number"
        value={formData.periodContract}
        onChange={onChange}
        fullWidth
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Cancel</Button>
      <Button variant="contained" onClick={onSave} color="primary">
        Save
      </Button>
    </DialogActions>
  </Dialog>
);

export default ResourcesDashboard;
